<script>
import { mapActions } from 'vuex';
import config from '@config';
import TableView from '@components/elements/TableView';

export default {
    extends: TableView,

    props: {
        selectAllText: {
            type: String,
            required: false,
            default: 'Select all'
        }
    },

    data() {
        return {
            pageTitle: 'Push Notifications',
            activeTab: { value: 'users' }
        };
    },

    computed: {
        tabsElements() {
            return [{ value: 'users', text: 'Users' }];
        },

        labels() {
            if (this.activeTab.value === 'users') {
                return [
                    { value: 'email', width: '200px' },
                    { value: 'first_name', width: '180px' },
                    {
                        value: 'last_name',
                        width: '180px'
                    },
                    {
                        value: 'phone',
                        width: '200px'
                    },
                    {
                        value: 'speciality',
                        width: '200px'
                    },
                    {
                        value: 'medical_institute',
                        width: '200px'
                    },
                    {
                        value: 'city',
                        width: '200px'
                    },
                    {
                        value: 'status',
                        width: '200px',
                        sortable: false
                    },
                    {
                        value: 'data_filled',
                        text: 'Data Filled',
                        width: '200px',
                        filter: 'formatBoolean',
                        type: 'boolean'
                    },
                    {
                        value: 'created_at',
                        filter: 'formatDate',
                        width: '200px',
                        type: 'date'
                    },
                    {
                        value: 'updated_at',
                        filter: 'formatDate',
                        width: '200px',
                        type: 'date'
                    }
                ];
            }

            return [
                { value: 'image', width: '200px' },
                { value: 'title', width: '180px' },
                {
                    value: 'date',
                    width: '180px',
                    filter: 'formatDate',
                    type: 'date'
                },
                {
                    text: 'Attending Users',
                    value: 'currently_attending',
                    width: '180px'
                },
                {
                    value: 'max_attending',
                    width: '180px'
                },
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    width: '200px',
                    type: 'date'
                },
                {
                    value: 'updated_at',
                    filter: 'formatDate',
                    width: '200px',
                    type: 'date'
                }
            ];
        },

        includeDeleteAction() {
            return false;
        },

        options() {
            return {
                addButton: false,
                selectAll: true,
                selectAllText: this.selectAllText
            };
        }
    },

    watch: {
        activeTab() {
            this.pagination.currentPage = 1;
            this.pagination.sortBy = 'created_at';
            this.fetchItems();
        }
    },

    methods: {
        ...mapActions({
            getDoctors: 'doctors/index',
            getEvents: 'events/index'
        }),

        getItems(data) {
            if (this.activeTab.value === 'users') {
                return this.getDoctors({ ...data, isPushNotifications: true });
            }

            return this.getEvents(data);
        },

        getImagePath(image) {
            return `${config.mediaUrl}/images/events/${image.date}/${image.id}.${image.extension}`;
        },

        getTokensFromUsers(selectedIds) {
            const selectedUsers = this.items.filter(
                user => user.device_token && selectedIds.includes(user.id)
            );

            return selectedUsers.map(user => user.device_token);
        },

        getTokensFromEvents(selectedIds) {
            const selectedEvents = this.items.filter(event =>
                selectedIds.includes(event.id)
            );
            const selectedUsers = selectedEvents.flatMap(event => event.users);

            return selectedUsers
                .map(user => user.device_token)
                .filter(token => token);
        },

        onSelectionChange(selectedIds) {
            const tokens =
                this.activeTab.value === 'users'
                    ? this.getTokensFromUsers(selectedIds)
                    : this.getTokensFromEvents(selectedIds);

            return this.$emit('selection-change', { tokens, ids: selectedIds });
        },

        onSelectAll(value) {
            this.$emit('select-all', value);
        }
    }
};
</script>
